/* eslint-disable react/jsx-indent */
/* eslint-disable @typescript-eslint/indent */
import React from 'react';
import { Link, graphql } from 'gatsby';
import { v4 as uuidv4 } from 'uuid';


import { kebabCase } from 'lodash';

// import Layout from '../components/layout';
import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';

import { css } from '@emotion/core';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
const PageTemplate = css`
  .site-main {
    background: #ececec;
    padding-bottom: 4vw;
  }
  .tagdivac{
    float: left;
    padding:5px;
    left: 2px;
    background-color:#004a57;
    width:120px;
    margin:20px;
    text-align:center;
    border-radius:20px;
    color:#f1f3f4;
    box-shadow:0px 0px 6px 1px #333333;
    background-image: url("https://www.transparenttextures.com/patterns/asfalt-light.png");
    /* Shadow */
    -moz-box-shadow:0px 0px 6px 1px #333333;
    -webkit-box-shadow:0px 0px 6px 1px #333333;
    box-shadow:0px 0px 6px 1px #333333;

    
    -webkit-transform: rotate(-6deg);
    -moz-transform: rotate(-6deg);
    -o-transform: rotate(-6deg);
    padding-bottom: 10px;
  }
`;


const tagDivTemplate={
    float:'left',
    left:2,
    backgroundolor:'#004a57',
    width:200,
    margin:10,
    textAlign:"center",
    borderRadius:20,
    color:'#f1f3f4',
    boxShadow:'0px 0px 6px 1px #333333',
    backgroundImage: 'url("asfalt-light.png")'
}
// const rowid=uuidv4()
const TagsPage = ({ data }) => {
  const allTags = data.allMarkdownRemark.group;

  return (
    <IndexLayout>
        <Helmet>
      <title>Tags</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav isAbout />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>
        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>Tags</PostFullTitle>
          </PostFullHeader>

          <PostFullContent className="post-full-content">
          <div>
        {/* <h1>Tags</h1> */}
        {allTags.map(tag => (
            // <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
            //     <div style={{position:'absolute',float:'left',left:2,backgroundColor:'#323232',width:200,margin:10,textAlign:"center",borderRadius:20,color:'#f1f3f4'}}></div>
            //  </Link>
            <Link to={`/tags/${kebabCase(tag.fieldValue)}/`} key={uuidv4()} >
            <div  className='tagdivac' css={PageTemplate}>{kebabCase(tag.fieldValue)} ({kebabCase(tag.totalCount)})</div>
            </Link>
            //<a href={`/tags/${kebabCase(tag.fieldValue)}/`}>{tag.fieldValue} ({tag.totalCount})</a>
            
          ))}
        {/* {/* {allTags.map(tag =>(
            <div style={{backgroundColor:'red'}}>{tag.fieldValue} ({tag.totalCount})</div>
        )} */}
        {/* <ul>
          {allTags.map(tag => (
            <li key={tag.fieldValue}>
              <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                  <div style={{backgroundColor:'red'}}>{tag.fieldValue} ({tag.totalCount})</div>
                
              </Link>
            </li>
          ))}
        </ul> */}
      </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
      
    </IndexLayout>
  );
};

export default TagsPage;

export const pageQuery = graphql`
  query {
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;